/* 
NO LONGER USING ANY OF THIS, LEAVING IT IN CASE WE WANT TO DO SOMETHING WITH COLORING CATEGORIES
---CATS LIST
‘Audit/Inspection/Maintenance’
‘Load Control’
‘Meter Deployment’
‘Network Install’
‘Network Installation’
‘Other’
‘Radio Deployment’
‘Retirement Testing’
‘Rework’
‘Site Testing’
‘Software Only’
‘Water Deployment’

.gantt-color-codes.other .k-task-single{
    background-color: rgb(0, 137, 128); }
.gantt-color-codes.other .k-task-complete{
    background-color: rgb(0, 88, 82);
}

.gantt-color-codes.default-dates{
    background-color: rgb(220, 220, 220); }
.gantt-color-codes.default-dates .k-grid-content-sticky{
    background-color: rgb(240, 240, 240);
}

.gantt-color-codes.water-deployment .k-task-single{
    background-color: rgb(93, 0, 255); }
.gantt-color-codes.water-deployment .k-task-complete{
    background-color: rgb(72, 0, 198);
}

.gantt-color-codes.meter-deployment .k-task-single{
    background-color: blue; }
.gantt-color-codes.meter-deployment .k-task-complete{
    background-color: rgb(2, 2, 180);
} 

.gantt-color-codes.software-only .k-task-single{
    background-color: blue; }
.gantt-color-codes.software-only .k-task-complete{
    background-color: blue; 
}

.gantt-color-codes.site-testing .k-task-single{
    background-color: blue; }
.gantt-color-codes.site-testing .k-task-complete{
    background-color: rgb(2, 2, 180);
}

.gantt-color-codes.rework .k-task-single{
    background-color: blue; }
.gantt-color-codes.rework .k-task-complete{
    background-color: rgb(2, 2, 180);
}

.gantt-color-codes.retirement-testing .k-task-single{
    background-color: blue; }
.gantt-color-codes.retirement-testing .k-task-complete{
    background-color: rgb(2, 2, 180);
}

.gantt-color-codes.radio-deployment .k-task-single{
    background-color: blue; }
.gantt-color-codes.radio-deployment .k-task-complete{
    background-color: rgb(2, 2, 180);
}

.gantt-color-codes.network-installation .k-task-single{
    background-color: blue; }
.gantt-color-codes.network-installation .k-task-complete{
    background-color: rgb(2, 2, 180);
}

.gantt-color-codes.network-install .k-task-single{
    background-color: blue; }
.gantt-color-codes.network-install .k-task-complete{
    background-color: rgb(2, 2, 180);
}

.gantt-color-codes.audit-inspection-maintenance .k-task-single{
    background-color: blue; }
.gantt-color-codes.audit-inspection-maintenance .k-task-complete{
    background-color: rgb(2, 2, 180);
}

.gantt-color-codes.load-control .k-task-single{
    background-color: blue; }
.gantt-color-codes.load-control .k-task-complete{
    background-color: rgb(2, 2, 180);
} */


/* CURRENT STATUSES = [
    'Planning',
    'In Progress',
    'On Hold',
    'Completed'
]; */


/* for some reason position gets unset with the dynamic headers in kendo */
/* setting it here fixes the issue */
.k-gantt-content th {
    top: 0;
}

.gantt-color-codes.planning .k-task-single{
    background-color: #2185d0;}
.gantt-color-codes.planning .k-task-complete{
    background-color: #1d6ca8;
} 
.gantt-color-codes.in-progress .k-task-single{
    background-color: #21ba45;}
.gantt-color-codes.in-progress .k-task-complete{
    background-color: #188131;
} 
.gantt-color-codes.on-hold .k-task-single{
    background-color: #fbbd08;}
.gantt-color-codes.on-hold .k-task-complete{
    background-color: #c69708;
} 
.gantt-color-codes.completed .k-task-single{
    background-color: rgba(49, 49, 49, 1);}
.gantt-color-codes.completed .k-task-complete{
    background-color: rgb(35, 35, 35);
}





.gantt-color-codes.default-dates{
    background-color: rgb(220, 220, 220); }
.gantt-color-codes.default-dates .k-grid-content-sticky{
    background-color: rgb(240, 240, 240);
}





.gantt-color-codes.category-header > td{
    border-bottom: 1px  #0F79CE;
    color: white;
    border-right: 0;
}

.gantt-color-codes.category-header{
    background-color: #0F79CE;
    border-bottom: 1px  #0F79CE;
    color: white;
    border-right: 0;
}
.gantt-color-codes.category-header .k-grid-content-sticky{
    background-color: #0F79CE;
    color: white;
    border-right: 0;
}
.gantt-color-codes.category-header:hover td{
    background-color: #0969b8;
    color: white;
}
.gantt-color-codes.category-header:hover div{
    border-bottom: 1px  #0969b8;
    background-color: #0969b8;
    color: white;
}
.gantt-color-codes.category-header:hover{
    border-bottom: 1px  #0969b8;
    background-color: #0969b8;
    color: white;
}


#gantt-inactive-toggle{
    display: flex;
    gap: 10px;
}
.k-gantt-toolbar.k-gantt-header{
    display: none;
}
#custom-gantt-toolbar{
    display: flex;
}