.map {
  position: absolute;
  top: 65px;
  right: 0;
  left: 0;
  bottom: 0;
}

.options {
  z-index: 10;
  left: 15px;
}

.options-content {
  display: flex;
  justify-content: center;
}

.options-header-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.options-header {
  margin: 0 !important;
}

.button-group {
  width: 100%;
}

.workorders-mapview-tool-bar {
  display: flex;
  z-index: 10;
  width: 80%;
  margin: 0 1em;
}

.mapboxgl-ctrl-top-right {
  top: 70px;
  right: 6px;
}

.workorders-mapview-tool-bar-menu {
  width: 100%;
}

.workorders-mapview-tool-bar-menu-item {
  flex: 1 !important;
}

.map-view {
  top: 282px;
  right: 15px;
  background: white;
  position: absolute;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  width: 30.5px;
}

.map-view {
  top: 282px;
  right: 15px;
  background: white;
  position: absolute;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  width: 30.5px;
}

.map-view-without-draw-control {
  top: 217px;
  right: 15px;
  background: white;
  position: absolute;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  width: 30.5px;
}

.map-view-button {
  display: flex !important;
  justify-content: center !important;
}

.legend {
  z-index: 1;
  position: absolute;
  bottom: 31px;
  right: 8px;
}

.legend-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.legend-image {
  margin-right: 10px;
}

.legend-image-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.legend-title {
  padding-bottom: 6px;
}