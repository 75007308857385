.google-map-message-container {
  display: flex;
  padding: 10px !important;
}

.google-map-message-container-header {
  width: 100%;
}

.google-map-message-container-header-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
}



.pdf-modal{
  width: 80vw;
  height: 80vh;
}
.pdf-modal .content{
  height: 100%;
}
.geo-coding-buttons{
  display: flex;
  flex-direction: column;
}
.geo-coding-buttons button{
  margin-top: 10px !important;
}
.geo-coding-buttons button:first-child{
  margin-top: 0 !important;
}
/* Class is automatically generated by the <object> element in HTML5 */
.pdf-viewer{
  width: 100%;
  height: 100%;
}