.k-datetime-container {
  top: 132px !important;
}

.k-state-selected{
  box-shadow: none !important;
}

.k-event{
  padding: 1px 16px 1px 1px !important;
}

.k-event.custom-selected{
  border: 1px solid black !important;
  outline: 1px solid black !important;
  padding: 0px 15px 0px 0px !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.k-resize-handle{
  display: none !important;
}

.k-slot-cell.k-state-selected{
  background-color: #ffffffff !important;
}

.scheduler-assignments-button{
  border: 1px solid rgba(0, 0, 0, 0.538) !important;
  max-width: 240px;
}

.custom-scheduler-toolbar{
  justify-content: space-between;
  margin-bottom: 12px !important;
  display: flex;
}
.custom-scheduler-toolbar div{
  align-items: center;
  display: flex;
}

.custom-scheduler-toolbar p{
  margin-left: 12px;
}

.custom-agenda-view .k-scheduler-timecolumn{
  min-width: 0 !important;
  flex: none !important;
}
.custom-agenda-view .k-scheduler-datecolumn{
  min-width: 115px !important;
  flex: none !important;
  width: 15%;
}
.custom-selected-slot{
  background-color: rgb(220, 232, 252);
}
.custom-selected-slot:hover{
  background-color: rgb(200, 220, 253) !important;
}
.custom-slot:hover{
  cursor: pointer;
}
.duplicate-address-warning:hover{
  cursor: pointer;
}
.shared-address-container p {
  margin: 0;
}
.shared-address-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1em;
}

.k-middle-row .k-scheduler-cell {
  border-bottom: 1px solid #00000000 !important;
}
.k-scheduler-cell.k-heading-cell{
  /* border-bottom: 1px solid #00000000; */
  color: rgb(0, 0, 0);
  font-weight: 400 !important;
}

.k-custom-date-header{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.k-custom-date-header .header-day{
  font-size: 12px;
}
.k-custom-date-header .header-date{
  font-size: 26px;
  font-weight: 400 !important;
  line-height: 46px;
  height: 46px;
  width: 46px;
  text-align: center;
}
.k-event{
  box-shadow: -1px 0px white !important;
}
.past-date{
  color: #7f7f7f !important;
}
.day-view-today-header .k-nav-day{
  background-color: #1079ce;
  color: white !important;
  border-radius: 50px;
  max-width: 120px;
}
.today-header .header-date{
  background-color: #1079ce;
  color: white !important;
  border-radius: 50%;
}
.today-header .header-day{
  font-weight: 700 !important;
  color: #1079ce !important;
}

.csr-capacity-inputs{
  background-color: rgba(0, 0, 0, 0.07) !important;
  border-width: 0 0 2px !important;
  border-radius: 3px !important;
  padding-left: 8px !important;
}