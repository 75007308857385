.route-status {
  padding-bottom: 15px;
}

.route-status .k-grid-toolbar {
  background: #0F79CE !important;
}

.workorder-status-by-route-table-title {
  color: white;
  font-size: 17px;
}