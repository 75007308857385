/* APP */
.app {
  display: flex;
  height: 100%;
  background: #F2F2F2;
  background: linear-gradient(180deg, rgba(242,242,242,1) 0%, rgb(220, 220, 220) 100%);
  overflow: auto;
}

.app-main-content {
  width: 100%;
  min-height: 100vh;
}

/* LOGIN PAGE */
.login-form {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  height: 100%;
  background: #235BA5;
  background: linear-gradient(180deg, rgba(35,91,165,1) 0%, rgb(19, 57, 106) 100%);
  width: 100%;
}

.login-form-container {
  height: 100%;
  max-height: 500px;
}

.complete-new-password-challange-form-container {
  height: 100%;
  max-height: 500px;
}

.send-verification-code-form-container {
  height: 100%;
  max-height: 500px;
}

.change-password-form-container {
  height: 100%;
  max-height: 500px;
}

.sign-in-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.login-logo {
  padding-bottom: 20px;
}

/* MENU */
.menu-logo {
  font-size: 18px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 !important;
}

.menu-header {
  font-size: 16px !important;
}

.menu-item {
  font-size: 15px !important;
  padding: .25em 1.33333333em !important;
}

.menu-item-small {
  font-size: 12px !important;
  margin-left: 4px !important;
}

/* INVENTORY IMPORT ASSETS PAGE */
.inventory-import-assets-listview-grid {
  font-size: 12px !important;
}

/* WORKORDERS PAGE */
.workorders-listview {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.workorders-listview-grid {
  font-size: 12px !important;
}

.workorders-listview-tool-bar {
  display: flex;
  padding-bottom: 12px;
}

.workorder-details-column a {
  text-align: center;
}

.workorder-details-modal-content-list {
  padding-bottom: 20px !important;
}

.workorder-details-modal-content-list-item {
  font-size: 14px !important;
}

/* PROJECTS PAGE */
.projects {
  height: 100%;
}

.projects-dropdown {
  padding-bottom: 13px;
}

.projects-table {
  margin-top: 10px;
}

/* QUALITY-ASSURANCE PAGE */
.quality-assurance-in-review {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.quality-assurance-escalated {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.quality-assurance-completed {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.quality-assurance-in-review-grid {
  font-size: 12px !important;
}

.quality-assurance-escalated-grid {
  font-size: 12px !important;
}

.quality-assurance-completed-grid {
  font-size: 12px !important;
}

.quality-assurance-tool-bar {
  display: flex;
  padding-bottom: 12px;
}

.quality-assurace-workorder-details-input input {
  font-size: 19px;
  font-weight: bold;
  width: 82px;
}

.quality-assurace-workorder-details-label {
  font-size: 19px;
  font-weight: bold;
  width: 82px;
}

/* INVENTORY REPORTING PAGE */
.inventory-report-list-view {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.inventory-report-list-view-grid {
  font-size: 12px !important;
}

.inventory-report-list-view-tool-bar {
  display: flex;
  padding-bottom: 12px;
}

/* LOGO */
.logo {
  height: 500px !important;
}

/* INVENTORY ALL ASSETS PAGE */
.inventory-all-assets-list-view {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.inventory-all-assets-list-view-grid {
  font-size: 12px !important;
  height: 93%;
}

.inventory-all-assets-list-view-tool-bar {
  display: flex;
  padding-bottom: 12px;
}

/* WORKORDERS REPORT PAGE */
.workorders-report {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
}

.workorders-report-list-view-grid {
  font-size: 12px !important;
}

.workorders-report-list-view {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.workorders-report-list-view-tool-bar {
  display: flex;
  padding-bottom: 12px;
}

/* SIDE MENU */
.side-menu {
  background: rgba(49, 49, 49, 1);
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgb(34, 34, 34) 100%);
  position: fixed;
  z-index: 1000;
  height: 100%;
}

.side-menu::-webkit-scrollbar {
  display: none;
}

.side-menu::-webkit-scrollbar {
  -ms-overflow-style: none;
}

.line-break {
  max-width: 272px;
  overflow-wrap: break-word;
}

.assignments-table, table, th, td {
  border: 1px solid rgb(245, 245, 245);
  border-collapse: collapse;
}

.assignments-table, th, td {
  padding: 15px;
  text-align: left;
}

/* ADMINISTRATION PROJECTS */
.administration-projects-workorders-listview {
  height: 60%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 20px;
}

html, body, #app, #app>div {
  height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
  overflow: auto;
}

.modal-size {
  max-height: calc(95vh - 10em) !important;
}

.k-grid-columnmenu-popup {
  width: 400px !important;
}

.k-grid-toolbar:first-child {
  padding-left: 15px;
}

/* PLANNING PAGE */
.planning-plan-tab-view {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.plan-tab-table {
  margin-top: 10px;
}

.planning-plan-results-view {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

/* WORKORDER DETAILS */
.details-description {
  height: 100%;
}

.details-description > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.details-menu-content {
  flex: 1;
  margin-top: 10px;
  overflow: auto;
}

/* QUALIFICATIONS */
.qualifications-listview {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 64px;
}

.qualifications-listview-tool-bar {
  display: flex;
  padding-bottom: 12px;
}

.qualifications-listview-grid {
  font-size: 12px !important;
}

/* SHARED */
.more-dropdown {
  text-align: center !important;
  align-items: center !important;
  display: flex !important;
}

/* SCHEDULING */
.needs-attention{
  background-color: red !important;
}
.greyed-out{
  background-color: #9d9d9d !important;
}

.unAssigned {
  /* background-color: #616161 !important; */
  background-color: #505050 !important;
}

.assigned1 {
  background-color: #286ba3  !important;
}
.assigned2 {
  background-color: #2b86d0  !important;
}
.assigned3 {
  background-color: #3CAEA3  !important;
}
.assigned4 {
  background-color: #00b2d7  !important;
}
.assigned5 {
  background-color: #689b2d !important;
}
.assigned6 {
  
  background-color: #39ae35ca !important;
}
.assigned7 {
  background-color: #9dc76c !important;
}
.assigned8 {
  background-color: #73ab69 !important;
}
.assigned9 {
  background-color: #926d92 !important;
}
.assigned10 {
  background-color: #d4a4c0 !important;
}


/* LEAVING THIS IN CASE THE COLORS I CHOSE DON'T MAKE SENSE */
/* .assigned1 {
  background-color: #577590  !important;
}
.assigned2 {
  background-color: #43AA8B  !important;
}
.assigned3 {
  background-color: #90BE6D  !important;
}
.assigned4 {
  background-color: #F3722C  !important;
}
.assigned5 {
  background-color: #F8961E  !important;
}
.assigned6 {
  background-color: #F9C74F  !important;
}
.assigned7 {
  background-color: #B4AA99  !important;
}
.assigned8 {
  background-color: #00ECD3  !important;
}
.assigned9 {
  background-color: #00B39D  !important;
}
.assigned10 {
  background-color: #98B0A9  !important;
}
.assigned11 {
  background-color: #A26952  !important;
}
.assigned12 {
  background-color: #ADBC00  !important;
} */
.route-wrapper {
  height: 90vh;
}