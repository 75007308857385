.workOrders-statistic {
  margin: 0 !important;
}

.plan-modal-header {
  display: flex;
  justify-content: space-between;
}

.plan-weekly-capacity {
  display: flex;
  padding-top: 15px;
}

.plan-weekly-capacity-label {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px !important;
}

.k-animation-container {
  z-index: 9999 !important;
}

.days-per-week {
  display: flex;
  margin-top: 10px;
}

.days-per-week-label {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px !important;
}

.override-checkbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
}

/* .editable-cell td {
  padding: 5px;
  text-align: center;
  border: solid 1px lightgray;
} */