.step-group {
  margin: 0 !important;
}

.export-report-type-input {
  margin-right: 20px;
}

.export-dropdown {
  margin-right: 30px;
  width: 260px;
  max-width: 300px !important;
}

.export-transformation-modal-dropdown {
  margin-right: 10px;
  width: 260px;
  max-width: 300px !important;
}

.export-transformation-type-dropdown {
  margin-right: 30px;
  width: 350px;
  max-width: 350px !important;
}

.export-condition-dropdown {
  margin-right: 30px;
  width: 260px;
  max-width: 300px !important;
  margin-bottom: 10px;
}

.export-condition-small-dropdown {
  margin-right: 30px;
  margin-bottom: 10px;
}

.export-small-dropdown {
  min-width: 130px !important;
  margin-right: 30px;
}

.export-operation-value-type-small-dropdown {
  min-width: 130px !important;
  margin-right: 10px;
}

.export-operation-value-input {
  margin-right: 10px;
}

.export-input {
  margin-right: 30px;
}

.export-template-option {
  margin-bottom: 10px;
}

.export-option {
  margin-bottom: 10px;
}

.export-option-right-margin {
  margin-right: 10px;
}

.right-margin {
  margin-right: 10px;
}

.export-template-option-checkbox {
  margin-right: 10px;
}

.export-transformation-value-type-small-dropdown {
  min-width: 130px !important;
  margin-right: 10px;
}

.export-transformation-value-input {
  margin-right: 10px;
}

.export-template-transformation-values-table {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.accordion-no-padding {
  margin: 0 !important;
}

.segment-no-padding {
  margin: 0 !important;
}

div.jsoneditor{
  height: 1000px !important;
}

.exchanged-limit-input {
  margin-left: 10px;
}

.remove-icon {
  cursor: pointer;
}

.workorder-row-background {
  background-color: rgb(220, 219, 219);
}

.overview-step-table{
  margin: 0 !important;
}