.k-card-header .k-card-title, .k-card-header .k-card-subtitle {
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.card-body-info-header{
    font-weight: bold;
    font-size: 16px;
}
.card-body-separator {
    width: 100%;
    height: 1px;
    background-color: rgb(171, 171, 171);
    margin-bottom: 9px;
}
/* .csr-portal-title {
    text-align: center;
    text-decoration: underline;
} */
.csr-header-bar {
    gap: 20px;
    display: flex;
    padding: 12px;
    border: 1px solid #22242667;
    align-items: center;
    /* just copying semantic UI */
    border-radius: .28571429rem;
    margin-bottom: 25px;
    background-color: white;
}
.csr-header-bar > form{
    gap: 13px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.csr-header-bar h1 {
    font-size: 24px;
    margin: 0;
}
/* Comment cards used for comments and history notes. */
.comment-card {
    margin-bottom: 16px;
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
}
.comment-card-header {
    display: flex;
    gap: 10px;
    margin-bottom: 6px;
}
.comment-card-header > .sub-title {
    font-size: 12px;
    align-self: flex-end;
    color: #7b7b7b;
}
.comment-card-header > .title {
    font-weight: bold;
    margin: 0;
}
.comment-card-body {
    padding-left: 10px;
}
/* Kendo styling override */
.csr-details-modal .k-animation-container.k-animation-container-relative {
    width: 100%;
}
/* Table Styling */
.details-table {
    width: 100%;
    border-collapse: collapse;
}
.details-table, .details-table td, .details-table th {
    border: 1px solid rgb(147, 147, 147);
}
.details-table td:first-child {
    /* background-color: rgb(246, 149, 149); */
    width: 35%;
    font-weight: bold;
}

/* Text Area */
.details-modal-text-area {
    margin-top: 25px;
    resize: none;
    width: 100%;
    height: 140px;
    max-width: 450px;
}

.loader-wrapper .ui.dimmer {
    z-index: 1003;
}

.csr-phone-input-valid > .k-textbox{
    border: 1px solid black !important;
    padding: 8px;
    border-radius: 6px;
}
.csr-phone-input-invalid > .k-textbox{
    border: 1px solid red !important;
    padding: 8px;
    border-radius: 6px;
}
.copied-to-clipboard-notification{
    position: absolute;
    display: none;
    background-color: #424242;
    color: white;
    padding: 3px 10px;
    border-radius: 3px;
}
.copied-to-clipboard-notification-error{
    position: absolute;
    display: none;
    background-color: #d43030;
    color: white;
    padding: 3px 10px;
    border-radius: 3px;
}
.custom-task:hover{
    cursor: pointer;
}
.confirm-details-contact-container .k-maskedtextbox {
    width: 100%;
}

.checkbox-container{
    text-align: left;
    margin-left: 5px;
}
.confirm-details-contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.confirm-details-contact-container .k-form-error{
    width: 100%;
}
.confirm-details-contact-container .k-textbox{
    width: 100%;
}
.confirm-details-contact-container .k-textarea{
    width: 100%;
}
.confirm-details-contact-email{
    margin-top: 4px !important;
}

.asterisk {
    color: red;
    margin-left: 4px;
}
.asterisk.valid {
    color: black;
}


.csr-call-status-success-checkbox{
    margin: 15px 0 25px 0;
    font-size: 30px;
}

.csr-call-status-success-checkbox input{
    width: 30px;
    height: 30px;
}
.csr-call-status-success-checkbox input::before{
    width: 25px;
    height: 25px;
    font-size: 25px;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    background: white;
    padding: 2em;
    text-align: center;
}





.csr-grid table{
    table-layout: fixed;
    line-height: normal;
}
.csr-grid td{
    padding: 0 6px !important;
    font-size: 12px;
}

.csr-grd {
    font-size: 12px !important;
}

.csr-grid::-webkit-scrollbar {
    display: none;
}
.csr-grid .k-grid-content.k-virtual-content::-webkit-scrollbar{
    display: none;
}






/* CONTROLLING STUFF FOR THE FILTER SECTION */
.csr-grid tr.k-filter-row{
    height: 12px;
}
.csr-grid .k-filter-row th{
    /* height: 12px; */
    padding: 0 12px !important;
    font-size: 12px;
}
.csr-grid .k-filter-row .k-filtercell-wrapper{
    align-items: baseline;
    font-size: 12px !important;
}
.csr-grd input{
    font-size: 12px;
}
.csr-grid .k-icon{
    font-size: 16px !important;
}
.csr-grid .k-icon.header-icon{
    font-size: 13px !important;
    padding-left: 4px !important;
}
/* .csr-grid .k-i-filter{
    font-size: 16px !important;
} */
.csr-grid input{
    height: 12px !important;
}
.csr-grid .k-input{
    font-size: 12px !important;
}
.csr-grid tr:nth-child(even){
    background-color: rgb(229, 228, 228) !important;
}
.csr-grid tr:nth-child(even):hover{
    background-color: lightgray !important;
}
.csr-grid .k-header{
    padding: 5px;
}





.k-grid-norecords td{
    padding: 25px !important;
    font-size: 20px !important;
}





.k-animation-container.k-animation-container-relative.k-list-container.k-reset{
    min-width: max-content !important;
}