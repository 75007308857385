.k-splitbar {
  width: 15px !important;
  background: white !important;
}

.k-icon {
  font-size: 21px !important;
}

.k-dialog-wrapper {
  z-index: 1002 !important;
}
