.workorder-report-toolbar-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.workorder-report-workorder-status-dropdown-label {
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 2px;
  margin-left: 10px;
}

.workorder-report-checkbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 20px;
}

.workorder-report-preview-export-container {
  margin-left: 10px;
  margin-top: 20px;
}

@media (max-width: 1135px){
  .workorder-report-toolbar-container {
    flex-direction: column;
    margin-bottom: 10px;
    padding-top: 0px;
  }

  .workorder-report-workorder-status-dropdown-label {
    margin-left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .workorder-report-workorder-status-dropdown {
    display: flex;
    margin-top: 10px;
  }

  .workorder-report-checkbox {
    padding-top: 10px;
    padding-left: 0px;
  }

  .workorder-report-preview-export-container {
    margin-left: 0;
    margin-top: 10px;
  }
}