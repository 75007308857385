.grid-header {
  color: white;
  font-size: 17px;
}

.planning-results-grid {
  margin-top: 10px;
  margin-bottom: 15px;
}

.planning-results-grid .k-grid-toolbar {
  background: #0F79CE !important;
}