.remove-avatar {
  cursor: pointer;
  color: red;
}

.badge-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  padding-right: 10px;
}