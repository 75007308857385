.production-status {
  margin-top: 10px;
  margin-bottom: 15px;
}

.production-status .k-grid-toolbar {
  background: #0F79CE !important;
}

.workorder-production-status-table-title {
  color: white;
  font-size: 17px;
}