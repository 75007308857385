.segment {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 0em;
  height: 100%;
}

.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  height: 100%;
}