.features-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .features-column {
    
  }
  
  .feature {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
    margin: 5px 0;
    padding: 2px 0;
  }
  
  .feature:hover {
    text-decoration: none;
    color: #0056b3;
  }
  
  .section-header {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  
  .card-video {
    max-height: 400px !important;
  }

  .k-card-body {
    padding-bottom: 0px;
  }